export const camelCaseToTitle = (str) => {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, (str) => {
    return str.toUpperCase();
  });
};

export const snakeCaseToTitle = (str) => {
  return str.replace(/_/g, " ").replace(/^./, (str) => {
    return str.toUpperCase();
  });
};

// removes dashes and underscores from a string and converts to camelCase
export const toCamelCase = (str) => {
  return str.replace(/[-_]+(.)?/g, (_, chr) => {
    return chr ? chr.toUpperCase() : "";
  });
};

export const spacesToDashes = (str) => {
  return str.replace(/ /g, "-");
};
