import React from "react";
import classNames from "../../../utils/twClassNames";
const BadgeWrapper = ({ children, isClickable }) => {
  const clickableClass = isClickable ? "cursor-pointer rounded-full" : "";
  return (
    <li
      className={classNames(
        "flex items-center p-1 space-x-1 border rounded-lg flex-shrink-0",
        clickableClass
      )}
    >
      {children}
    </li>
  );
};

export default BadgeWrapper;
