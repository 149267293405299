export const officeLocationsData = [
  {
    location: "Boston",
    type: "Biofoundry",
    name: "Foundry:Zero",
    altName: "Cambridge",
    image: "boston",
    isComingSoon: false,
  },
  {
    location: "San Francisco",
    type: "Operations",
    altName: "Bay Area",
    isComingSoon: true,
    image: "sanFrancisco",
  },
];
