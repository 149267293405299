import React from "react";
import FullWidthPictureHero from "../components/shared/heroes/FullWidthPictureHero";
import Layout from "../components/shared/Layout";
import Seo from "../components/seo/Seo";
import { socialShareCardImage } from "../imgix/social-card";
import { SmallSecondaryHeading } from "../components/shared/Headings";
import { officeLocationsData } from "../data/career-data";
import { cdnBase } from "../data/internal-links";
import { spacesToDashes } from "../utils/regex";
import { HeartIcon, SunIcon, TrendingUpIcon } from "@heroicons/react/outline";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "@imgix/gatsby/src/modules/gatsby-transform-url/gatsbyPluginImage";
import OpenCareersSection from "../components/careers/OpenCareersSection";

// TODO: Learn how to export a nested react component
const employeeBenefitsData = [
  {
    benefit: "401k match",
    description:
      "We match 5% of your 401k, and you can choose to contribute more or less.",
    icon: TrendingUpIcon,
    color: "text-green-500",
    body: (
      <>
        Plan for your future with our{" "}
        <span className="text-green-500">401k match.</span>
      </>
    ),
  },
  {
    benefit: "Health, dental and vision",
    description: "We cover your health insurance, dental, and vision benefits.",
    icon: HeartIcon,
    color: "text-red-500",
    body: (
      <>
        <span className="text-red-500">Health, vision and dental</span> premiums
        are completely covered.
      </>
    ),
  },
  {
    benefit: "Unlimited PTO",
    description: "Unlimited paid time off.",
    icon: SunIcon,
    color: "text-yellow-500",
    body: (
      <>
        <span className="text-yellow-500">Unlimited PTO</span> for when you need
        it most.
      </>
    ),
  },
];

export default function Careers() {
  const socialImgUri = socialShareCardImage(
    "scientist.jpg",
    "Your discoveries shape our future.",
    "Careers",
    "careers"
  );
  return (
    <Layout>
      <Seo
        title="Careers & Synthetic Biology Jobs"
        image={{
          src: socialImgUri,
          width: 1200,
          height: 630,
        }}
      />
      <FullWidthPictureHero
        title={"Careers"}
        cta={"View our positions ↓"}
        copy={"We're engineering the cells that will feed billions of people."}
        imgUri={"https://cultura.imgix.net/scientist.jpg"}
        imgAlt={"Person inspecing"}
        linkUri={"/careers/#open-positions"}
        heroImageBgColor="#3843d4"
      />

      <section className="pt-8 pb-16 mx-auto xl:container">
        <SmallSecondaryHeading>Benefits</SmallSecondaryHeading>
        <ul className="grid grid-rows-3 gap-4 px-4 xl:grid-rows-1 xl:grid-cols-6 xl:px-0">
          {employeeBenefitsData.map((benefit) => (
            <li
              className="flex flex-col p-8 bg-zinc-100 rounded-3xl xl:col-span-2"
              key={benefit.benefit}
            >
              <benefit.icon
                className={`flex-shrink-0 w-12 h-12 lg:w-16 lg:h-16 ${benefit.color}`}
                aria-hidden="true"
              />
              <p className="text-2xl font-bold lg:text-4xl">{benefit.body}</p>
            </li>
          ))}
        </ul>
      </section>
      <section className="py-16 mx-auto xl:px-0 xl:container" id="offices">
        <SmallSecondaryHeading>
          Biofoundries & Offices.{" "}
          <span className="text-slate-500">We're rapidly expanding.</span>
        </SmallSecondaryHeading>
        <ul className="flex px-12 space-x-4 overflow-x-auto lg:grid lg:space-x-0 xl:px-0 lg:gap-2 ">
          {officeLocationsData.map((office) => (
            <li className="flex-shrink-0 mt-4" key={office.location}>
              <div className="relative flex h-56 w-80 lg:h-64 xl:h-80 2xl:h-96 lg:w-auto">
                <div className="z-10 flex flex-col w-full px-8 py-3 mt-auto bg-white rounded-b-md bg-opacity-10 bg-clip-padding backdrop-filter backdrop-blur-xl">
                  <p className="text-sm font-semibold truncate lg:text-lg text-slate-100">
                    {office.location}
                    <span> / {office.altName}</span>
                  </p>
                  <p className="text-xs font-medium text-slate-200 lg:text-base">
                    {office.type} {office.isComingSoon ? "— Opening Soon" : ""}
                  </p>
                </div>
                <div className="absolute z-0 flex object-cover w-full h-full">
                  <GatsbyImage
                    image={getGatsbyImageData({
                      src: cdnBase(
                        spacesToDashes(office.location.toLowerCase()) + ".jpg"
                      ),
                      aspectRatio: 22 / 9,
                      imgixParams: {
                        auto: "compress,format",
                      },
                      layout: "constrained",
                    })}
                    alt={office.location}
                    className="w-full h-full rounded-sm bg-slate-900"
                  />
                </div>
              </div>
            </li>
          ))}
          <li className="w-4 h-4 text-transparent lg:hidden">.</li>
        </ul>
      </section>
      <OpenCareersSection />
    </Layout>
  );
}

// export const query = graphql`
//   query {
//     careersHero: imgixImage(url: "/scientist.jpg") {
//       gatsbyImageData(
//         layout: FULL_WIDTH
//         aspectRatio: 2.44444
//         placeholder: DOMINANT_COLOR
//         imgixParams: { fit: "crop", crop: "faces" }
//       )
//     }
//     boston: imgixImage(url: "/boston.jpg") {
//       gatsbyImageData(
//         layout: CONSTRAINED
//         aspectRatio: 2.44444
//         placeholder: BLURRED
//         width: 1200
//         imgixParams: { fit: "crop" }
//       )
//     }
//     sanFrancisco: imgixImage(url: "/san-francisco.jpg") {
//       gatsbyImageData(
//         layout: CONSTRAINED
//         aspectRatio: 2.44444
//         placeholder: BLURRED
//         imgixParams: { fit: "crop" }
//       )
//     }
//   }
// `;
