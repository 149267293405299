import React from "react";
import CtaButton from "../buttons/CtaButton";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "@imgix/gatsby/src/modules/gatsby-transform-url/gatsbyPluginImage";

// TODO: make the cta generic
const FullWidthPictureHero = ({
  title,
  cta,
  imgUri,
  copy,
  imgAlt,
  linkUri,
  image,
  heroImageBgColor,
}) => {
  return (
    <section className="relative flex w-full h-116 xl:h-140">
      <div className="z-10 flex flex-col w-full h-full ">
        <div className="flex w-full h-full px-4 mx-auto xl:px-0 xl:container">
          <div className="w-full p-8 my-auto rounded-xl xl:w-auto xl:p-8 backdrop-blur-3xl">
            <div className="relative space-y-4 md:container md:mx-auto xl:space-y-8">
              <h1 className="-mb-2 text-4xl font-semibold leading-none tracking-tighter text-left text-white md:text-6xl xl:text-7xl">
                {title}
              </h1>
              <p className="pr-1 ml-1 text-lg font-semibold leading-tight text-white md:text-3xl md:max-w-3xl xl:text-4xl ">
                {copy}
              </p>
              <div className="flex w-full h-full text-right">
                <CtaButton variant="glass" to={linkUri}>
                  {cta}
                </CtaButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute z-0 w-full h-full">
        <GatsbyImage
          image={
            image
              ? image
              : getGatsbyImageData({
                  src: imgUri,
                  aspectRatio: 2,
                  imgixParams: {
                    auto: ["format", "compress"],
                    q: 80,
                    format: "crop",
                    crop: "faces",
                  },
                  backgroundColor: heroImageBgColor
                    ? heroImageBgColor
                    : "black",
                  layout: "fullWidth",
                })
          }
          imgClassName="w-full h-full object-cover"
          className="flex w-full h-full bg-slate-900"
          alt={imgAlt ? imgAlt : "Hero Image"}
          loading="eager"
        />
      </div>
    </section>
  );
};
export default FullWidthPictureHero;
