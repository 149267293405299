import React from "react";
import {
  LocationMarkerIcon,
  OfficeBuildingIcon,
  HomeIcon,
  DocumentTextIcon,
} from "@heroicons/react/outline";
import BadgeWrapper from "../shared/badges/BadgeWrapper";
// TODO: Learn import badges dynamically or assign them
const CareerCardBadge = ({ badgeType, info }) => {
  return (
    <BadgeWrapper className="flex items-center flex-shrink-0 p-1 space-x-1 border rounded-lg">
      {badgeType === "location" && (
        <LocationMarkerIcon className="w-6 h-6 text-slate-500 " />
      )}
      {badgeType === "office" && (
        <OfficeBuildingIcon className="w-6 h-6 text-slate-500 " />
      )}
      {badgeType === "home" && <HomeIcon className="w-6 h-6 text-slate-500 " />}
      {badgeType === "contract" && (
        <DocumentTextIcon className="w-6 h-6 text-slate-500 " />
      )}
      <div className="w-full text-sm font-semibold text-slate-600 ">{info}</div>
    </BadgeWrapper>
  );
};

export default CareerCardBadge;
