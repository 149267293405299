import React from "react";
import CareerCardBadge from "./CareerCardBadge";
import classNames from "../../utils/twClassNames";
import { snakeCaseToTitle } from "../../utils/regex";

const CareerCard = ({ id, position, isRemote, city, contractDetails }) => {
  return (
    <li className="w-full p-8 border rounded-3xl md:flex bg-zinc-50">
      <div className="space-y-4 overflow-hidden md:flex-grow">
        <p className="text-xl font-bold md:text-4xl text-slate-900">
          {position}
        </p>
        <ul className="flex mt-2 space-x-2 overflow-x-auto">
          {city && <CareerCardBadge info={city} badgeType={"location"} />}
          {isRemote && (
            <CareerCardBadge info={"Work from home"} badgeType={"home"} />
          )}
          {contractDetails && (
            <CareerCardBadge
              info={snakeCaseToTitle(contractDetails)}
              badgeType={"contract"}
            />
          )}
        </ul>
      </div>
      <div className="flex items-center justify-between mt-5 md:flex-grow-0 md:mt-0 md:py-2 md:flex-col">
        <a
          rel="nofollow"
          href={`https://www.careers-page.com/cultura/job/${id}/apply`}
          className={classNames(
            "cursor-pointer rounded-full border-2 border-blue-600 text-blue-600 px-3 py-1 font-semibold w-full text-center bg-white",
            "hover:bg-blue-600 hover:text-white"
          )}
        >
          Apply Now
        </a>

        <a
          rel="nofollow"
          href={`https://www.careers-page.com/cultura/job/${id}`}
          className="w-full text-center cursor-pointer text-slate-500 hover:text-slate-900"
        >
          Learn More
        </a>
      </div>
    </li>
  );
};
export default CareerCard;
