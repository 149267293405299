import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  SmallSecondaryHeading,
  SmallSecondaryHeadingSpan,
} from "../shared/Headings";
import CareerCard from "./CareerCard";

const OpenCareersSection = () => {
  const allOpenCareersQuery = useStaticQuery(graphql`
    query {
      allOpenCareers {
        nodes {
          id
          position
          isRemote
          city
          contractDetails
        }
      }
    }
  `);

  const careers = allOpenCareersQuery.allOpenCareers.nodes;
  return (
    <section
      className="py-16 mx-auto space-y-4 xl:container xl:px-0 "
      id="open-positions"
    >
      <SmallSecondaryHeading id="open-careers">
        Open Positions.{" "}
        <SmallSecondaryHeadingSpan>
          Not listed, but insanely talented? Contact us.
        </SmallSecondaryHeadingSpan>
      </SmallSecondaryHeading>
      <ul className="grid gap-4 px-4 xl:px-0">
        {careers &&
          careers.map((career) => (
            <CareerCard
              position={career.position}
              isRemote={career.isRemote}
              city={career.city}
              contractDetails={career.contractDetails}
              id={career.id}
              key={career.id}
            />
          ))}
      </ul>
    </section>
  );
};

export default OpenCareersSection;
